import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import { Faqs } from "components";

function FaqsPage({ location, data }) {
  return (
    <Layout location={location} pageTitle="FAQS">
      <Faqs items={data.faqsJson.content} />
    </Layout>
  );
}

export default FaqsPage;

export const query = graphql`
  query FaqQuery {
    faqsJson {
      content {
        question
        answer
      }
    }
  }
`;
